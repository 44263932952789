<ul class="nt-not-list auth-footer">
    <li><a [href]="termsUrl">{{ 'SPECIAL.TERMS_OF_USE' | translate }}</a></li>
    <li>
        <a [href]="privacyLink">{{ 'SPECIAL.PRIVACY_POLICY' | translate }}</a>
    </li>
    <li>
        <a [href]="helpLink">{{ 'MENUS.CONTACT' | translate }}</a>
    </li>
    <li><a [href]="youtubeUrl" target="_blank">{{ 'STRINGS.YOUTUBE' | translate }}</a></li>
</ul>
