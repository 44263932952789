import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { finalize, Observable, of } from 'rxjs';
import { AppSyncService } from '@app/common/services/app-sync.service';
import { AuthManagerService } from '@app/core/services/auth-manager.service';
import { IdentityApiService } from '@app/core/services/identity.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private appSyncService: AppSyncService,
                private authManager: AuthManagerService,
                private identity: IdentityApiService,
                private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authManager.isLoggedIn) { return of(true); }

        return new Observable(observer => {
            this.identity.getIcentralUserDetails()
                .pipe(finalize(() => observer.complete()))
                .subscribe({
                    next: res => {
                        this.appSyncService.startConnection();
                        observer.next(true);
                    },
                    error: err => {
                        this.router.navigate(['/login']);
                        observer.next(false);
                    }
                });
        });

    }

}
