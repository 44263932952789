import {
  AutoFlowStart,
  AutoFlowStop,
  AutoLearnStatus,
  FlowMeterRecord,
  RetrieveFlowAccountController
} from '@app/shared/models/command-api-interfaces.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  ICommandResults, IScheduledCommand, IScheduledCommandCreateRequest, IScheduledCommandUpdateRequest,
  SUBMIT_COMMAND
} from '@app/shared/models/api-interfaces.model';
import { BaseApiService } from './api-helper.service';
import { ControllerInfo } from '@app/features/_models/controller-info.model';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()

export class CommandingService extends BaseApiService {
  constructor(private http: HttpClient) {
    super(http, environment.commandingApiUrl + '/api/');
  }

  /*
   * !!! COMMUNICATION TEST !!!
   */
  readonly CommandsEndpoint = 'Commands';


  private readonly AutoLearnEndpoint = 'Manual-Ops/AutoLearn';
  private readonly RetrieveFlowEndpoint = 'Manual-Ops/RetrieveFlow';

  /** GET /api/Commands/guid
   *
   * Performs API request to get commands/guid.
   */
  getCommTestGuidHeader() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      responseType: 'text' as 'json',
    };
    console.log('guid');
    return this.http.get<string>(
      this.getApiCall(this.CommandsEndpoint + '/guid'),
      httpOptions);
  }

  private createManualOpsHeader(headerID: string) {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-request-id': headerID }),
      observe: 'response' as 'response'
    };
  }

  checkCommTestResult(controllerID: string) {
    return this.get<ICommandResults>(this.getApiCall(this.CommandsEndpoint, controllerID));
  }

  deleteCommTestResult(controllerID: string) {
    return this.delete(this.getApiAppend(this.CommandsEndpoint, controllerID));
  }

  /** POST /api/Commands
   *
   * Performs API Submit Test Post .
   */
  submitCommTest(headerID: string, controllerID: string, accountID: string) {
    const body = { 'controllerID': controllerID, 'type': SUBMIT_COMMAND.TEST_COMMUNICATION, 'accountId': accountID };
    return this.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  stationManualStart(headerID: string, controllerInfo: ControllerInfo, stationNumber: number, counter: number, accountID: string) {
    const body = {
      'controllerInfo': controllerInfo, 'type': SUBMIT_COMMAND.START_STATION, 'stationNumber': stationNumber,
      'runtime': counter, 'accountId': accountID
    };

    return this.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  stationManualStop(headerID: string, controllerInfo: ControllerInfo, stationNumber: number, accountID: string) {
    const body = {
      'controllerInfo': controllerInfo, 'type': SUBMIT_COMMAND.STOP_STATION, 'stationNumber': stationNumber,
      'accountId': accountID
    };
    return this.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  programManualStartStop(headerID: string, controllerInfo: ControllerInfo, programNumber: number, submitCommand: SUBMIT_COMMAND, accountID: string) {
    const body = JSON.stringify(
      { 'controllerInfo': controllerInfo, 'type': submitCommand, 'programNumber': programNumber, 'accountId': accountID }
    );
    return this.http.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  rainShutDownChangeMode(headerID: string, controllerInfos: { controllerId: string, cloudId: string }[], rainShutDownDays: string, accountID: string,
    submitCommand: SUBMIT_COMMAND) {
    console.log('api');
    const body = { 'controllerInfos': controllerInfos, 'type': submitCommand, 'rainShutDownDays': rainShutDownDays, 'accountId': accountID };
    return this.http.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  powerCycle(headerID: string, controllerID: string, accountID: string) {
    const body = { 'controllerID': controllerID, 'type': SUBMIT_COMMAND.RECYCLE_POWER, 'accountId': accountID };
    return this.post(
      this.getApiCall(this.CommandsEndpoint),
      body,
      this.createManualOpsHeader(headerID));
  }

  /**
    * !!! AUTO LEARN FLOW !!!
    */

  autoLearnStart(controllerID: string, percent: number) {
    const body = new AutoFlowStart();
    body.controllerID = controllerID;
    body.percent = percent;
    return this.post(this.getApiCall(this.AutoLearnEndpoint), body);
  }

  autoLearnStop(controllerID: string) {
    const body = new AutoFlowStop();
    body.controllerID = controllerID;
    return this.delete(this.getApiCall(this.AutoLearnEndpoint, controllerID));
  }


  getAutoLearnStatus(controllerId: string) {
    return this.get<AutoLearnStatus>(this.joinApiCalls(this.getApiCall(this.AutoLearnEndpoint, controllerId), 'Active'));
  }

  getLastAutoLearn(controllerId: string) {
    return this.get<Date>(this.joinApiCalls(this.getApiCall(this.AutoLearnEndpoint, controllerId), 'LastCompleted'));
  }


  /**
    * !!! Retrieve Flow !!!
    */

  private mapFlowMeterValues(val: FlowMeterRecord) {
    if (!val) {
      return;
    }
    val.whenRetrieved = new Date(val.whenRetrieved);
    if (isNaN(val.whenRetrieved.getTime())) {
      val.whenRetrieved = undefined;
    }
  }

  getRetrieveFlowAccountControllers() {
    return this.get<RetrieveFlowAccountController[]>(this.getApiCall(this.RetrieveFlowEndpoint)).pipe(
      map(res => {
        res.body.map(b => {
          b.flowMeterStatuses.map(f => {
            this.mapFlowMeterValues(f.value1);
            this.mapFlowMeterValues(f.value2);
            return f;
          });
          return b;
        });
        return res;
      })
    );
  }

  submitRetrieveFlow(controllerIds: string[]) {

    return this.post(this.getApiCall(this.RetrieveFlowEndpoint), controllerIds).pipe(
      map(res => {
        const body = <RetrieveFlowAccountController[]>res.body;
        body.map(b => {
          b.flowMeterStatuses.map(f => {
            this.mapFlowMeterValues(f.value1);
            this.mapFlowMeterValues(f.value2);
            return f;
          });
          return b;
        });
        return <HttpResponse<RetrieveFlowAccountController[]>>{
          body: body,
          ...res
        };
      }));
  }

  /* !!! ScheduledCommand !!! */

  private scheduledCommandApi() {
    return this.getApiCall('scheduledcommand');
  }

  getScheduledCommandsByAccountId(accountId: string) {
    const uri = `${this.scheduledCommandApi()}/accountid/${accountId}/?latest=true`;
    return this.get<IScheduledCommand>(uri);
  }


  createScheduledCommandsByAccountId(req: IScheduledCommandCreateRequest) {
    const uri = this.scheduledCommandApi();

    return this.post(uri, req);
  }

  updateScheduledCommandsById(commandId: string, req: IScheduledCommandUpdateRequest) {
    const uri = `${this.scheduledCommandApi()}?id=${commandId}`;

    return this.put(uri, req);
  }
}

