export class CcrRunningProgram {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	number: number;
	programType: string;				// "Program" | "Establishment" | "ISC"
	runMode: string;					// "MANUAL" | "AUTO"
	stationRunning: number;
	repeatsRemaining: number;
}
