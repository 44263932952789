import { CcrRunningOutput } from '@app/common/services/models/cloud-connect/ccr-running-output.model';
import { CloudConnectResponse } from '@app/common/services/models/cloud-connect/cloud-connect-response.model';

export class CcrControllerStatus extends CloudConnectResponse {

	constructor(json: any = null) {
		super(json);

		if (json) {
			if (json.onlineSince) this.onlineSince = new Date(json.onlineSince);
			if (json.outputsRunning) this.outputsRunning = json.outputsRunning.map(o => new CcrRunningOutput(o));
		}
	}

	cloudID: string;
	displayControllerID: string;
	description: string;
	online = false;
	onlineSince: Date;
	activePrograms: number;
	activeStations: number;
	alerts: number;
	stationCount: number;
	masterValves: number;
	pumps: number;
	pumpOperations: string;
	rainHoldDays: string;
	flowRate: number;
	flowRateUnits: string;
	controllerType: string;
	location: string;
	expirationTimestamp: number;
	expirationStatus: number;			// 0 = Normal Operation, 1 = Warn service expiring soon, 2 = Controller service has expired
	expirationMessage: string;
	outputsRunning: CcrRunningOutput[] = [];
}
