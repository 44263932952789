import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { OnboardingCarousel } from '@app/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ic-auth-carousel',
    templateUrl: './auth-carousel.component.html',
    providers: [
        { provide: CarouselConfig, useValue: { interval: 5000 } }
    ],
    styleUrls: ['./auth-carousel.component.scss']
})
export class AuthCarouselComponent implements OnInit {
    slides: OnboardingCarousel[];
    noWrapSlides = false;
    showIndicator = true;

    constructor(private ts: TranslateService) { }

    ngOnInit() {
        this.slides = [
            {
                img: 'assets/img/onboarding/controller.png',
                title: this.ts.instant('HEADER.SIMPLIFIED_CONTROL_TITLE'),
                content: this.ts.instant('PARAGRAPHS.SIMPLIFIED_CONTROL_CONTENT'),
                class: 'simplified-control l-shadow',
                bgImg: 'assets/img/onboarding/golf_bg.jpg'
            },
            {
                img: 'assets/img/onboarding/iCentral_mobile.png',
                title: this.ts.instant('HEADER.CONTROL_ANYWHERE_TITLE'),
                content: this.ts.instant('PARAGRAPHS.CONTROL_ANYWAY_CONTENT'),
                class: 'control-anywhere sxs-col',
                qrCode: 'proMaxQR'
            },
            {
                img: 'assets/img/onboarding/iCentral_tab.png',
                title: this.ts.instant('HEADER.THE_NEXT_EVOLUTION_TITLE'),
                content: this.ts.instant('PARAGRAPHS.THE_NEXT_EVOLUTION_CONTENT'),
                class: 'next-evolution xl-shadow',
            }
        ];
    }
}
