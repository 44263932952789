import CloudConnectAction = ToroEnums.CloudConnectAction;

import { finalize, Observable, Subject, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountsManagerService } from '@app/api/accounts/accounts-manager.service';
import { AWSAppSyncClient } from 'aws-appsync';
import { BroadcastService } from '@app/common/services/broadcast.service';
import { CcrControllers } from '@app/common/services/models/cloud-connect/ccr-controllers.model';
import { CcrControllerStatus } from '@app/common/services/models/cloud-connect/ccr-controller-status.model';
import { CloudConnectResponse } from '@app/common/services/models/cloud-connect/cloud-connect-response.model';
import { environment } from '@env/environment';
import gql from 'graphql-tag';
import { Injectable} from '@angular/core';
import { ToroEnums } from '@app/common/enumerations/toro.enums';
import { UserManagerService } from '@app/api/identity/user-manager.service';
import { v4 as uuidv4 } from 'uuid';

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class AppSyncService {

	// Subscriptions
	appSyncResponseReceived = new Subject<any>();

	private readonly ReconnectDelayMS = 15000;

	private subscribeDoc = `
        subscription SubscribeToData {
            subscribe(name: "$channel") {
                name
                data
            }
        }`;

	private _appSyncObservable: Observable<any>;
	private _appSyncObserverCount = 0;
	private _awsAppSyncClient: AWSAppSyncClient<any>;
	private _hydratedClientObservable: Subscription;

	// =========================================================================================================================================================
	// Ctor and Lifecycle Hooks
	// =========================================================================================================================================================

	constructor(private accountsManager: AccountsManagerService,
				private broadcastService: BroadcastService,
				private userManager: UserManagerService
	) {
		this.broadcastService.userLoggedOut
			.pipe(untilDestroyed(this))
			.subscribe(() => this.stopConnection());
	}

	// =========================================================================================================================================================
	// Public Methods
	// =========================================================================================================================================================

	private static _channelName = uuidv4();
	static get channelName(): string {
		return this._channelName;
	}

	startConnection() {
		this._appSyncObserverCount++;
		if (this._appSyncObservable != undefined) return this._appSyncObservable;

		console.log("Initializing AppSync Connection...");

		this.userManager.getCurrentUserAccount()
			.subscribe({
				next: userAccount => {
					if (userAccount.roleId > 6) {
						// We will use the currently logged-in user's primary account Id (designated as the first account in their list of accounts) as the
						// channel name for AppSync communications. This will are only sending AppSync notifications to users authorized to receive them for
						// entities related to this account. This avoids sending AppSync notifications to all iCentral users, which would be very chatty and a
						// breach of security/privacy.
						this.accountsManager.getAccountsForCurrentUser()
							.pipe(finalize(() => this.startConnectionInternal(AppSyncService.channelName)))
							.subscribe({
								next: accounts => {
									if (accounts.length >= 1) AppSyncService._channelName = accounts[0].accountID;
								}
							});

					} else {
						// Admin user - use unique, default channel. This will only be sent to this instance of iCentral
						this.startConnectionInternal(AppSyncService.channelName);
					}
				},
				error: error => {
					if (error.status == 401) return;

					// Use unique, default channel. This will only be sent to this instance of iCentral
					this.startConnectionInternal(AppSyncService.channelName);
				}
			});
	}

	private startConnectionInternal(channelName: string) {
		// Create an observable that will pass-thru the data we receive from AppSync.
		// This way we can control the life cycle of our observables more carefully.
		this._appSyncObservable = new Observable(observer => {
			this.awsAppSyncClient.hydrated().then((hydratedClient: any) => {
				if (this._hydratedClientObservable != undefined) return;

				const subscribeString = this.subscribeDoc.replace('$channel', channelName);
				this._hydratedClientObservable = hydratedClient
					.subscribe({ query: gql(subscribeString), variables: {} })
					.subscribe((response: any) => {
							observer.next(response);
							// this.handleAppSyncResponse(response);
						},
						error => {
							// Set the count to 1 so stopConnection() will shutdown everything (it decrements the value and checks for zero).
							this._appSyncObserverCount = 1;
							this.stopConnection();

							// Restart the connection in a few seconds
							setTimeout(() => this.startConnection(), this.ReconnectDelayMS);
						});
			});
		});

		this._appSyncObservable.subscribe({
			next: response => {
				this.processResponse(response?.data?.subscribe?.data);
			},
			error: err => {
				// Set ref count to 1 so stopConnection() will complete the disconnect process.
				this._appSyncObserverCount = 1;
				this.stopConnection();

				// Restart the connection in a few seconds
				setTimeout(() => this.startConnection(), this.ReconnectDelayMS);
			}
		});
	}

	stopConnection() {
		if (--this._appSyncObserverCount > 0) return;
		if (this._hydratedClientObservable == null) return;

		this._hydratedClientObservable.unsubscribe();
		this._hydratedClientObservable = null;
		this._appSyncObservable = null;
	}

	// =========================================================================================================================================================
	// Helper Methods
	// =========================================================================================================================================================

	private get awsAppSyncClient(): AWSAppSyncClient<any> {
		if (this._awsAppSyncClient != null) return this._awsAppSyncClient;

		this._awsAppSyncClient = new AWSAppSyncClient({
			url: environment.awsAppSyncUrl,
			region: environment.awsAppSyncRegion,
			auth: {
				type: 'API_KEY',
				apiKey: environment.awsAppSyncApiKey
			}
		});

		return this._awsAppSyncClient;
	}

	private processResponse(responseString: string) {
		try {
			const response = new CloudConnectResponse(JSON.parse(responseString));

			switch (response.actionName) {
				case CloudConnectAction.RainHold:
					// TODO: Proper processing of action type.
					break;

				case CloudConnectAction.Controllers:
					this.broadcastService.cc_controllersChange.next(new CcrControllers(JSON.parse(responseString)));
					break;

				case CloudConnectAction.ControllerStatus:
					this.broadcastService.cc_controllerStatusChange.next(new CcrControllerStatus(JSON.parse(responseString)));
					break;

				case CloudConnectAction.ManualOperation:
					this.broadcastService.cc_manualOperationAction.next(response);
					break;

				case CloudConnectAction.ProgramStartOrStop:
					this.broadcastService.cc_programStartOrStopAction.next(response);
					break;

				default:
					// TODO: Proper processing of action type. Ultimately, we should never hit this case.
					break;
			}

			console.log(`AppSync Response Received: ${response.actionName} - ${response.actionStatus}`);

			// // TODO: For testing until to verify broadcast
			// this.appSyncResponseReceived.next(response);

		}
		catch (ex) {
			// TODO: Temporary? Catch any errors during development.
			console.log(`ERROR HANDLING APPSYNC RESPONSE: ${ex}`);
		}
	}

}


