export class CcrRunningOutput {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	number: number;
	outputType: string;				// "STATION" | "MASTER" | "PUMP"
	reason: string;					// "MANUAL" | "AUTO" | "MV/PUMP" | "???"
	outputOffTime: number;			// UNIX Epoch Time
	confirmedOn: boolean;

	get timeRemainingSeconds(): number {
		if (this.outputOffTime == null) { return 0; }

		return this.outputOffTime - Math.floor(Date.now() / 1000);
	}
}
