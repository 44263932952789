import { CcrControllers } from '@app/common/services/models/cloud-connect/ccr-controllers.model';
import { CcrControllerStatus } from '@app/common/services/models/cloud-connect/ccr-controller-status.model';
import { CloudConnectResponse } from '@app/common/services/models/cloud-connect/cloud-connect-response.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BroadcastService {

	// =========================================================================================================================================================
	// General Events
	// =========================================================================================================================================================

	userLoggedOut = new Subject<void>();

	// =========================================================================================================================================================
	// Cloud Connect Events
	// =========================================================================================================================================================

	cc_controllersChange = new Subject<CcrControllers>();
	cc_controllerStatusChange = new Subject<CcrControllerStatus>();
	cc_manualOperationAction = new Subject<CloudConnectResponse>();
	cc_programStartOrStopAction = new Subject<CloudConnectResponse>();

}
