// includes all common models except API models.

export class WindowSize {
    width: number;
    height: number;

    constructor(size: { width: number, height: number }) {
        Object.assign(this, size);
    }
}

export class OnboardingCarousel {
    img: string;
    title: string;
    content: string;
    class: string;
    bgImg?: string;
    qrCode?: string;

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }
}
