import { CommonModule } from '@angular/common';
import { IconSvgComponent } from './icon-svg.component';
import { NgModule } from '@angular/core';
import { PromaxQrCodeComponent } from './promax-qr-code/promax-qr-code.component';

@NgModule({
    declarations: [
        IconSvgComponent,
        PromaxQrCodeComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [IconSvgComponent]
})
export class IconSvgModule {}
