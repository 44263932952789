import { AuthCarouselComponent } from '@app/shared/layout/auth-carousel/auth-carousel.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IconSvgModule } from '@app/shared/icon-svg/icon-svg.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CarouselModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        IconSvgModule
    ],
    declarations: [
        AuthCarouselComponent,
    ],
    exports: [
        AuthCarouselComponent
    ]
})
export class AuthCarouselModule {}
