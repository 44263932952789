<div class="nt-carousel carousel-wrap carousel-fill-height nt-flex onboarding-carousel">
    <header>
        <h1><img src="assets/img/icentral_logo_white.png" alt="iCentral"></h1>
    </header>
    <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator" class="nt-flex-col nt-flex-fill-height">
        <slide *ngFor="let slide of slides; let index=index" class="slide-wrap nt-fill-height" [ngClass]="slide.class">
            <div class="slide-bg nt-flex" [style]="slide.bgImg? {'background-image': 'url(' +slide.bgImg +')' } : null">
                <article class="article">
                    <div class="slide-img">
                        <img [src]="slide.img" alt="image slide">
                    </div>
                    <div class="content-wrap">
                        <div *ngIf="slide.qrCode != null">
                            <ic-icon-svg [iconName]="slide.qrCode" class="qr-code-wrap"></ic-icon-svg>
                        </div>
                        <h4 class="slide-title" [innerHTML]="slide.title"></h4>
                        <p class="slide-content">{{ slide.content }}</p>
                    </div>
                </article>
            </div>
        </slide>
    </carousel>
</div>
