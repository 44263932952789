<span class="icon-box">
    <ng-container [ngSwitch]="iconName">
        <ng-container *ngSwitchCase="'clock'">
            <span class="fa-svg-icon svg-clock">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'conventionalRun'">
            <span class="fa-svg-icon svg-conventionalRun">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'cycleSkipRun'">
            <span class="fa-svg-icon svg-cycleSoakRun">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M208 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM416 32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0 160c0 27.6-11.7 52.5-30.4 70.1C422.1 275.7 448 310.8 448 352c0 53-43 96-96 96H160c-53 0-96-43-96-96s43-96 96-96h88.4c-15.2-17-24.4-39.4-24.4-64H96c-53 0-96 43-96 96V416c0 53 43 96 96 96H416c53 0 96-43 96-96V288c0-53-43-96-96-96zM160 288c-35.3 0-64 28.7-64 64s28.7 64 64 64H352c35.3 0 64-28.7 64-64s-28.7-64-64-64H320 160z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'etAdjustment'">
            <span class="fa-svg-icon svg-thermometer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M448 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM320 96a96 96 0 1 1 192 0A96 96 0 1 1 320 96zM144 64c-26.5 0-48 21.5-48 48V276.5c0 17.3-7.1 31.9-15.3 42.5C70.2 332.6 64 349.5 64 368c0 44.2 35.8 80 80 80s80-35.8 80-80c0-18.5-6.2-35.4-16.7-48.9c-8.2-10.6-15.3-25.2-15.3-42.5V112c0-26.5-21.5-48-48-48zM32 112C32 50.2 82.1 0 144 0s112 50.1 112 112V276.5c0 .1 .1 .3 .2 .6c.2 .6 .8 1.6 1.7 2.8c18.9 24.4 30.1 55 30.1 88.1c0 79.5-64.5 144-144 144S0 447.5 0 368c0-33.2 11.2-63.8 30.1-88.1c.9-1.2 1.5-2.2 1.7-2.8c.1-.3 .2-.5 .2-.6V112zM192 368c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-20.9 13.4-38.7 32-45.3V272c0-8.8 7.2-16 16-16s16 7.2 16 16v50.7c18.6 6.6 32 24.4 32 45.3z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'eye'">
            <span class="fa-svg-icon svg-eye">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M2.98444 9.12471L2.98346 9.12815C2.91251 9.3948 2.63879 9.55404 2.37202 9.4833C1.94098 9.36907 2.01687 8.87186 2.01687 8.87186L2.03453 8.81047C2.03453 8.81047 2.06063 8.72636 2.08166 8.6653C2.12369 8.54329 2.18795 8.37274 2.27931 8.16977C2.46154 7.76493 2.75443 7.22477 3.19877 6.68295C4.09629 5.58851 5.60509 4.5 8.00017 4.5C10.3952 4.5 11.904 5.58851 12.8016 6.68295C13.2459 7.22477 13.5388 7.76493 13.721 8.16977C13.8124 8.37274 13.8766 8.54329 13.9187 8.6653C13.9397 8.72636 13.9552 8.77541 13.9658 8.81047C13.9711 8.828 13.9752 8.84204 13.9781 8.85236L13.9816 8.865L13.9827 8.86916L13.9832 8.87069L13.9835 8.87186C14.0542 9.13878 13.8952 9.41253 13.6283 9.4833C13.3618 9.55397 13.0885 9.39556 13.0172 9.12937L13.0169 9.12815L13.0159 9.12471L13.0085 9.0997C13.0014 9.07616 12.9898 9.03927 12.9732 8.99095C12.9399 8.89422 12.8866 8.75227 12.8091 8.58023C12.6538 8.23508 12.4041 7.77523 12.0283 7.31706C11.2857 6.4115 10.0445 5.5 8.00017 5.5C5.95584 5.5 4.71464 6.4115 3.97201 7.31706C3.59627 7.77523 3.34655 8.23508 3.19119 8.58023C3.11375 8.75227 3.06047 8.89422 3.02715 8.99095C3.01051 9.03927 2.9989 9.07616 2.99179 9.0997L2.98444 9.12471ZM5.50024 10C5.50024 8.61929 6.61953 7.5 8.00024 7.5C9.38096 7.5 10.5002 8.61929 10.5002 10C10.5002 11.3807 9.38096 12.5 8.00024 12.5C6.61953 12.5 5.50024 11.3807 5.50024 10Z" fill="#080D12"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'eye-slash'">
            <span class="fa-svg-icon svg-eye">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M10.1196 11.3267L14.1464 15.3536C14.3417 15.5488 14.6583 15.5488 14.8536 15.3536C15.0488 15.1583 15.0488 14.8417 14.8536 14.6464L1.85355 1.64645C1.65829 1.45118 1.34171 1.45118 1.14645 1.64645C0.951184 1.84171 0.951184 2.15829 1.14645 2.35355L4.37624 5.58334C3.90117 5.9183 3.5126 6.30026 3.19877 6.68295C2.75443 7.22477 2.46154 7.76493 2.27931 8.16977C2.18795 8.37274 2.12369 8.54329 2.08166 8.6653C2.06063 8.72636 2.03453 8.81047 2.03453 8.81047L2.01687 8.87186C2.01687 8.87186 1.94098 9.36907 2.37202 9.4833C2.63879 9.55404 2.91251 9.3948 2.98346 9.12815L2.98444 9.12471L2.99179 9.0997C2.9989 9.07616 3.01051 9.03927 3.02715 8.99095C3.06047 8.89421 3.11375 8.75227 3.19119 8.58023C3.34655 8.23507 3.59627 7.77523 3.97201 7.31706C4.26363 6.96146 4.63213 6.60494 5.09595 6.30306L6.67356 7.88067C5.9688 8.32277 5.50024 9.10667 5.50024 10C5.50024 11.3807 6.61953 12.5 8.00024 12.5C8.89358 12.5 9.67747 12.0314 10.1196 11.3267ZM6.31962 4.69853L7.174 5.55291C7.43366 5.51852 7.70875 5.5 8.00017 5.5C10.0445 5.5 11.2857 6.4115 12.0283 7.31706C12.4041 7.77523 12.6538 8.23507 12.8091 8.58023C12.8866 8.75227 12.9399 8.89421 12.9732 8.99095C12.9898 9.03927 13.0014 9.07616 13.0085 9.0997L13.0159 9.12471L13.0169 9.12815L13.0172 9.12937C13.0885 9.39555 13.3618 9.55397 13.6283 9.4833C13.8952 9.41253 14.0542 9.13878 13.9835 8.87186L13.9832 8.87069L13.9827 8.86916L13.9816 8.865L13.9781 8.85236C13.9752 8.84204 13.9711 8.828 13.9658 8.81047C13.9552 8.77541 13.9397 8.72636 13.9187 8.6653C13.8766 8.54329 13.8124 8.37274 13.721 8.16977C13.5388 7.76493 13.2459 7.22477 12.8016 6.68295C11.904 5.5885 10.3952 4.5 8.00017 4.5C7.38264 4.5 6.82403 4.57236 6.31962 4.69853Z" fill="#080D12"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'irrigation'">
            <span class="fa-svg-icon svg-irrigation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                            d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'masterValve'">
            <span class="fa-svg-icon svg-masterValve">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM192 160H320c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'nonIrrigation'">
            <span class="fa-svg-icon svg-nonIrrigation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                            d="M269.5 69.9c11.1-7.9 25.9-7.9 37 0C329 85.4 356.5 96 384 96c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 149.7 417 160 384 160c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4C42.8 92.6 61 83.5 75.3 71.6c11.1-9.5 27.3-10.1 39.2-1.7l0 0C136.7 85.2 165.1 96 192 96c27.5 0 55-10.6 77.5-26.1zm37 288C329 373.4 356.5 384 384 384c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 437.7 417 448 384 448c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 373.2 165.1 384 192 384c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0zm0-144C329 229.4 356.5 240 384 240c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 293.7 417 304 384 304c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.5 27.3-10.1 39.2-1.7l0 0C136.7 229.2 165.1 240 192 240c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'proMaxQR'">
            <span class="fa-svg-icon svg-promax-qr">
                <ic-promax-qr-code></ic-promax-qr-code>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'pump'">
            <span class="fa-svg-icon svg-pump">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M256 64a192 192 0 1 1 0 384 192 192 0 1 1 0-384zm0 448A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM200 256c0-20.7 11.3-38.8 28-48.5l-36-62.3c-8.8-15.3-28.7-20.8-42-9c-25.6 22.6-43.9 53.3-50.9 88.1C95.7 241.5 110.3 256 128 256l72 0zm28 48.5l-36 62.4c-8.8 15.3-3.6 35.2 13.1 40.8c16 5.4 33.1 8.3 50.9 8.3s34.9-2.9 50.9-8.3c16.7-5.6 21.9-25.5 13.1-40.8l-36-62.4c-8.2 4.8-17.8 7.5-28 7.5s-19.8-2.7-28-7.5zM312 256l72 0c17.7 0 32.3-14.5 28.8-31.8c-7-34.8-25.3-65.5-50.9-88.1c-13.2-11.7-33.1-6.3-42 9l-36 62.3c16.7 9.7 28 27.8 28 48.5zm-56 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'rainSensor'">
            <span class="fa-svg-icon svg-rainSensor">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'rainShutdown'">
            <span class="fa-svg-icon svg-rainShutdown">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                            d="M320 512c53.2 0 101.4-21.6 136.1-56.6l-298.3-235C140 257.1 128 292.3 128 320c0 106 86 192 192 192zM505.2 370.7c4.4-16.1 6.8-33.1 6.8-50.7c0-91.2-130.2-262.3-166.6-308.3C339.4 4.2 330.5 0 320.9 0h-1.8c-9.6 0-18.5 4.2-24.5 11.7C277.8 33 240.7 81.3 205.8 136L38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L505.2 370.7zM224 336c0 44.2 35.8 80 80 80c8.8 0 16 7.2 16 16s-7.2 16-16 16c-61.9 0-112-50.1-112-112c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
                </svg>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'xMark'">
            <span class="fa-svg-icon svg-xMark">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                </svg>
            </span>
        </ng-container>
        <!--Default case icon is (?) -->
        <ng-container *ngSwitchDefault>
            <span class="fa-svg-icon svg-default">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                            d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                </svg>
            </span>
        </ng-container>
    </ng-container>
</span>
