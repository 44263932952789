import { CcrController } from '@app/common/services/models/cloud-connect/ccr-controller.model';
import { CcrOrganization } from '@app/common/services/models/cloud-connect/ccr-organization.model';
import { CloudConnectResponse } from '@app/common/services/models/cloud-connect/cloud-connect-response.model';

export class CcrControllers extends CloudConnectResponse {

	constructor(json: any = null) {
		super(json);

		if (json) {
			Object.assign(this, json);

			if (json.controllers) this.controllers = json.controllers.map(o => new CcrController(o));
		}
	}

	displayedOrganizationID: string;
	displayOrganizationName: string;
	displayedOrganizationType: string;		// "CUSTOMER" | "SUBORG"
	parentOrganizationID: string;
	controllers: CcrController[] = [];
	organizations: CcrOrganization[] = [];
}
