import { CcrRunningOutput } from '@app/common/services/models/cloud-connect/ccr-running-output.model';
import { CcrRunningProgram } from '@app/common/services/models/cloud-connect/ccr-running-program.model';

export class CcrController {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);

			if (json.onlineSince) this.onlineSince = new Date(json.onlineSince);
			if (json.lastSeenOnline) this.lastSeenOnline = new Date(json.lastSeenOnline);
			if (json.outputsRunning) this.outputsRunning = json.outputsRunning.map(o => new CcrRunningOutput(o));
			if (json.programsRunning) this.programsRunning = json.programsRunning.map(p => new CcrRunningProgram(p));
		}
	}

	number: number;
	cloudID: string;
	online = false;
	organizationID: string;
	clientOrganizationID: string;
	controllerType: string;
	firmwareVersion: string;
	firmwareRequired: string;
	rainHoldDays: string;
	rainHoldTime: number;			// Seconds Since 1970; -1 = Indefinite; 0 = No Rain Hold; else seconds since 1970 when rain hold expires
	displayControllerID: string;
	description: string;
	onlineSince: Date;
	lastSeenOnline: Date;
	activePrograms: number;
	activeStations: number;
	alerts: number;
	flowRate: number;
	flowUnits: string;
	pushNotifications: number;		// 0 = not capable of push notifications (must be polled); 1 = push notifications available.
	location: string;				// Latitude,Longitude
	expirationTimestamp: number;
	expirationStatus: number;		// 0 = Normal Operation, 1 = Warn service expiring soon, 2 = Controller service has expired
	expirationMessage: string;
	stationCount: number;
	masterValves: number;
	pumps: number;
	masterValveOperations: string;	// "ON" | "OFF" | "ON,OFF"
	pumpOperations: string;			// "ON" | "OFF" | "ON,OFF"
	iccid: string;
	imei: string;
	outputsRunning: CcrRunningOutput[] = [];
	programsRunning: CcrRunningProgram[] = [];
}
