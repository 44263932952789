<div id="extr-page" class="auth-wrap nt-flex">
    <div class="container-fluid nt-flex-col nt-flex-fill-height">
        <div class="row carousel-row nt-flex nt-flex-fill-height">
            <div class="col-lg-7  col-md-12 carousel-col">
                <ic-auth-carousel></ic-auth-carousel>
            </div>
            <div class="col-lg-5 col-md-12 auth-pages">
                <div class="auth-router-outlet">
                    <div class="router-wrap">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <ic-auth-footer></ic-auth-footer>
            </div>
        </div>
    </div>
</div>
