import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppSyncService } from '@app/common/services/app-sync.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    template: `
        <div class="row" *ngIf="isIE">
            <article class="col-sm-12">
                <alert type="danger">
                    <h4 class="alert-heading">Error!</h4>
                    Browser not supported!
                    <p>
                        <br>
                        <a href="https://www.google.com/chrome/" class="btn btn-sm btn-default"><strong>Download Chrome</strong></a>&nbsp;
                        <a href="https://www.mozilla.org/firefox/" class="btn btn-sm btn-default"><strong>Download Firefox</strong></a>&nbsp;
                        <a href="https://support.apple.com/downloads/#safari" class="btn btn-sm btn-default"><strong>Download Safari</strong></a>&nbsp;
                        <a href="https://www.microsoft.com/edge" class="btn btn-sm btn-default"><strong>Download Edge</strong></a>
                    </p>
                </alert>
            </article>
        </div>
        <router-outlet *ngIf="!isIE"></router-outlet>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'sa';

    public readonly isIE: boolean;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private appSyncService: AppSyncService) {
        this.isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    }

    ngOnInit() {
        // TODO: For Test and example. Place this wherever we might be interested in an AppSync response.
        //       We'll want to filter for proper message and/or create discrete Subjects (i.e., events).
        this.appSyncService.appSyncResponseReceived
            .pipe(untilDestroyed(this))
            .subscribe(response => {
                console.log(`AppSync response received in AppComponent: ${response}`);
            });
    }

    ngOnDestroy() {
        this.appSyncService.stopConnection();
    }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================
}
