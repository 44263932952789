export class CcRequestInfo {

	constructor(json: any = null) {
		if (json) {
			Object.assign(this, json);
		}
	}

	// Unique identifier for the account that owns the controller.
	accountId: string;

	// iCentral Controller Id (Guid)
	controllerId: string;

	// Cloud Connect Controller Id.
	cloudId: string;

	// Optional: Cloud Connect Action Name a Controller Status request is related to. Allows us to more discretely handle the Controller Status response.
	statusCommand: string;

}
