export namespace ToroEnums {

    export enum LanguageCode {
        English_US = 'en-us',
        Spanish = 'es-es',
        French = 'fr-fr',
        German = 'de-de',
        Italian = 'it-it',
        Portuguese = 'pt-pt',
        Dutch = 'nl-nl',
        Swedish = 'sv-se',
        Japanese = 'ja-jp',
        Korean = 'ko-ko',
        Chinese_Simplified = 'zh-hans',
        Vietnamese = 'vi',
        Russian = 'ru-ru',
        Bulgarian = 'bg-bg',
        Danish = 'da-dk',
        Norwegian = 'nn-no',
        Czech = 'cz-cz'
    }

    export enum CollapsibleDivState {
        Open,
        Closed
    }

    export enum CloudConnectActionStatus {
        Success = "SUCCESS",
        Fail = "FAIL",
        Timeout = "TIMEOUT"
    }

    export enum CloudConnectAction {
        Controllers = 'CONTROLLERS',
        ControllerStatus = 'CONTROLLERSTATUS',
        RainHold = 'RAINHOLD',
        ManualOperation = 'MANUALOPERATION',
        ProgramStartOrStop = 'PROGRAMSTARTORSTOP'
    }
}
