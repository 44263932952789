import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.qa';
import { LegacyLink } from '@app/shared/models';

@Component({
    selector: 'ic-auth-footer',
    templateUrl: './auth-footer.component.html',
    styleUrls: ['./auth-footer.component.scss']
})
export class AuthFooterComponent implements OnInit {

    youtubeUrl: string;

    protected termsUrl = LegacyLink.TermsOfUse;
    protected privacyLink = LegacyLink.PrivacyNPolicy;
    protected helpLink = LegacyLink.Help;

    constructor() { }

    ngOnInit() {
        this.youtubeUrl = environment.youtubeUrl;
    }
}
